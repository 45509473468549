import {bindable, BindingEngine, customElement, inject, LogManager, observable} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
import {DialogService} from 'aurelia-dialog';
import {I18N} from "aurelia-i18n";
import * as Promise from "bluebird";
import {Client} from '../../api/client';
import {UserClient} from '../../api/user-client';
import {ConditionMatcher} from "../../condition-builder/condition-matcher";
import {UniversalEntitySelect} from "../../dialog/universal-entity-select";
import {ProductChoice} from "../product-choice";
import {ConfigurationLoader} from "../../form/loader/configuration-loader";
import {FlashService} from "../../flash/flash-service";
import {StandardActions} from "../../action/standard-actions";
import {FlightDialog} from "../../dialog/flight-dialog";
import {ModuleConfigClient} from "../../api/module-config-client";
import * as _ from "lodash";
import moment from "moment";
import {InsuranceApiChoice} from "../insurance-api-choice";
import {ColumnsGenerator} from './cart-view/columns/columns-generator.js';
import {ExecutorRegistryFactory} from "./action-executor/executor-registry-factory";
import {BucketsGenerator} from "./buckets-generator";
import '../../array/array-move';
import {FlightSearch} from "../../flight/flight-search";
import {TicketSearch} from "../../deutsche-bahn/ticket-search";
import {RouteSearch} from "../../tourism/bus-routing/search/route-search";
import printJS from 'print-js';

import "./cart.less";
import {CostsDialog} from "../../costs/costs-dialog";
import {ProductSearch} from "../../offer/product-search";
import {LocaleService} from "../../i18n/locale-service";
import {FromDateBucketsGenerator} from "./from-date-buckets-generator";
import {ChargesDialog} from "./charges/charges-dialog";
import {TourismEventSearch} from "../../offer/tourism-event-search";
import {LogDialog} from "./log-dialog";
import {MultiProductChoice} from '../multi-product-choice';

const logger = LogManager.getLogger("OrderTourismCart");

@customElement('sio-order-tourism-cart')
@inject(
    Client, UserClient, ConditionMatcher, EventAggregator, BindingEngine, DialogService,
    ConfigurationLoader, FlashService, StandardActions, ModuleConfigClient, I18N,
    ColumnsGenerator,
    ExecutorRegistryFactory,
    BucketsGenerator,
    FromDateBucketsGenerator,
    LocaleService
)
export class OrderTourismCart {
    client;
    conditionMatcher;
    ea;
    bindingEngine;
    dialogService;
    formConfigLoader;
    flash;
    actions;
    i18n;

    @bindable isStockOrder = false;
    @bindable order;
    @bindable allItemsChecked;
    @bindable sorting = false;
    @observable selectedParticipants;
    @observable selectedItems;

    selectAllCheckbox;
    subscriptions;
    itemsObserver;
    sortable;
    addActions = [];
    loading;
    productColumn;
    participantColumn;

    buckets = [];
    items;
    participants;
    journeys;
    journeyTitle;
    uncollapsedItems = [];
    collapsibleItemsHaveBeenManuallyToggled = false;

    participantsEnabled = false;

    isParentOrder = true;

    showCalculationByDefault = false;

    addEmptyParticipantAction = {
        actionClass: 'btn btn-default btn-sm',
        label: 'TN',
        help: this.i18n?.tr('tourism-order.addParticipant'),
    };

    addParticipantAction = {
        actionClass: 'btn btn-default btn-sm',
        label: 'TN (K)',
        help: this.i18n?.tr('tourism-order.addCustomerAsParticipant'),
    };

    purchasePriceAction = {};

    calculatedPurchasePriceAction = {};

    showLogAction = {
        label: this.i18n?.tr('tourism-order.showLog')
    };

    openChargesContentConfig = {
        actionClass: 'btn btn-link btn-xs pull-right purchase-price-action font-weight-bold',
        icon: 'fa fa-piggy-bank',
        help: this.i18n?.tr('tourism-order.showDiscounts'),
    };

    defaultSortableOptions = {
        filter: '.not-sortable',
        //Important so that indexes are correct
        draggable: '.draggable',
        handle: '.fa-bars',
        onMove: event => !event.related.classList.contains('not-sortable')
    };

    defaultWithoutParticipant = {
        'participants': [],
        'sortableOptions': Object.assign(
            {},
            this.defaultSortableOptions,
            {
                group: {
                    name: 'without-participant',
                    put: function (e) {
                        logger.debug("Try to add item to without-participant group", e);

                        return true; // Allow moving any item from any group to "without-participant" group
                    }
                },
            }
        ),
    };

    constructor(client, userClient, conditionMatcher, ea, bindingEngine, dialogService, formConfigLoader,
                flash, actions, moduleConfigClient, i18n, columnsGenerator,
                executorRegistryFactory, bucketsGenerator, fromDateBucketsGenerator, localeService) {
        this.client = client;
        this.userClient = userClient;
        this.conditionMatcher = conditionMatcher;
        this.ea = ea;
        this.bindingEngine = bindingEngine;
        this.dialogService = dialogService;
        this.formConfigLoader = formConfigLoader;
        this.flash = flash;
        this.actions = actions;
        this.moduleConfigClient = moduleConfigClient;
        this.i18n = i18n;
        this.columnsGenerator = columnsGenerator;
        this.executorRegistry = executorRegistryFactory.createRegistry();
        this.bucketsGenerator = bucketsGenerator;
        this.fromDateBucketsGenerator = fromDateBucketsGenerator;
        this.localeService = localeService;
        this.loading = true;
        this.productProviders = {};
        this.journeys = new Map();
        this.participants = new Map();
        this.items = new Map();
        this.withoutParticipant = Object.assign({'items': []}, this.defaultWithoutParticipant);

        this.showCalculationByDefault = this.userClient.getUser().instance.settings.orderSettings.showCalculationByDefault;

        this.editItem = this.editItem.bind(this);
        this.client.get('order/product-prototype?sort[0][0]=shortName&sort[0][1]=ASC', 10).then(data => {
            this.productPrototypes = data.items;
            this.productPrototypeActions = [];

            for (const prototype of this.productPrototypes) {
                this.productPrototypeActions.push({
                    buttonClass: 'btn btn-default btn-sm',
                    label: prototype.shortName,
                    actionCallback: () => {

                        let data = {
                            participants: this.selectedParticipants,
                            product: {
                                id: prototype.id,
                                modelId: prototype.modelId
                            },
                            provider: 'order/product-prototype',
                        };

                        return this.client
                            .patch('order/order-item-add/trigger/' + this.order.id, data)
                            .then(() => this.reloadOrder());
                    }
                });
            }
        });
    }

    initializeToolbars(toolbars) {
        const menu = toolbars;
        let newMenu = [];

        for (const toolbar of menu) {

            if (toolbar.label != 'order.actions' && toolbar.label != 'EK' && toolbar.label != 'RV' && toolbar.label != 'tourism-service.additional-service-short') {
                continue;
            }

            for (const action of toolbar.actions) {
                action.buttonClass = action.buttonClass || 'btn btn-default btn-sm';
                action.showLabel = !action.icon && !action.textIcon;

                if (action.hasOwnProperty('workflowId')) {
                    action.type = 'workflow';
                    action.workflowId = _.castArray(action.workflowId)
                        .map(workflowId => (-1 === workflowId.indexOf('/') ? 'tourism/' : '') + workflowId);
                    if (1 === action.workflowId.length) {
                        action.workflowId = action.workflowId[0];
                    }
                }
            }

            newMenu.push(toolbar);
        }

        this.menu = newMenu;

        logger.debug('Cart view toolbars were initialized', menu);
    }

    attached() {
        this.subscriptions = [
            this.ea.subscribe('sio_form_post_submit', payload => {
                //Do not trigger reload by submitting product-choice form, we trigger it after workflow manually
                //Todo use event to reload order and trigger it at right time (after workflow execution)
                if (payload.config.id == 'order-item-add' || payload.config.modelId == 'tourism/flight' || payload.config.modelId == 'tourism-flight/itinerary') {
                    return;
                }
                if (/^(tourism)/.test(payload.config.modelId)) {
                    this.reloadOrder();
                }
            })
        ];
        this.observeItems();

        this.getOrderProductProvider();
        console.log("buckets", this.buckets);
    }

    reloadOrder() {
        this.ea.publish('sio_form_post_submit', {config: {modelId: 'order/order'}});
    }

    observeItems() {
        if (!this.itemsObserver) {
            this.itemsObserver = this.bindingEngine
                .collectionObserver(this.selectedItems)
                .subscribe(() => this.selectedItemsChanged());
        }
    }

    detached() {
        if (this.subscriptions) {
            this.subscriptions.forEach(subscription => subscription.dispose());
            this.subscriptions = null;
        }
        this.unobserveItems();
    }

    unobserveItems() {
        if (this.itemsObserver) {
            this.itemsObserver.dispose();
            this.itemsObserver = null;
        }
    }
    bind (){
        this.loading = false;
        this.orderChanged();
    }
    async orderChanged() {
        // avoid parallel executions
        if (this.loading) {
            return;
        }

        this.now = moment().format();

        this.loading = true;
        this.selectedParticipants = [];
        this.selectedItems = [];
        this.allItemsChecked = false;
        this.participants = new Map();
        this.journeys = new Map();
        this.items = new Map();
        this.journeyTitle = null;
        this.withoutParticipant = Object.assign({'items': []}, this.defaultWithoutParticipant);

        const endpoints = {
            config : this.isStockOrder ? 'inventory-management/cart-view/configuration/' : "order/cart-view/configuration/",
            orderItem : this.isStockOrder ? 'inventory-management/stock-order-item/?' : "order/order-item?embeds[]=product&",

        }
        this.client.get(endpoints.config + this.order.id).then(cartViewConfig => {
            this.initializeToolbars(cartViewConfig.toolbars);

            this.columns = this.columnsGenerator.generate(cartViewConfig);
        });

        if (!this.productColumn || !this.titleColumn) {
            let list = await this.client.get('order/order-item/list');

            this.productColumn = list.columns.find(column => 'product' === column.property);
            this.titleColumn = list.columns.find(column => 'title' === column.property);

            //Quite easy check for tourism modules atm
            this.participantsEnabled = list.embeds.indexOf('participants') > -1 && !this.order.b2bParticipants;
        }

        if(this.isStockOrder){
            this.linkedOrders = [this.order?.id];
        }else{
            this.linkedOrders = await this.client.get('order/linked-order/' + this.order.id);
        }

        let linkedOrdersConditions = [];

        for (let linkedOrder of this.linkedOrders) {
            linkedOrdersConditions.push('conditions[order][$in][]=' + linkedOrder);
        }

        let orderItemsConditions = [];

        if(!this.isStockOrder){
            if (this.order.parentId !== undefined && Object.keys(this.order.parentId).length ) {
                linkedOrdersConditions.push('conditions[order][$in][]=' + this.order.parentId?.id);
                this.isParentOrder = false;
            } else {
                try {
                    // get all item in sub orders
                    const subOrders = await this.client.get('order/sub-orders/' + this.order.id);
                    for (let subOrder of subOrders) {
                        orderItemsConditions.push('conditions[order][$in][]=' + subOrder);
                    }
                } catch (e) {
                }
            }
        }
        orderItemsConditions.push('conditions[order][$in][]=' + this.order.id);
        orderItemsConditions = orderItemsConditions.join('&');

        linkedOrdersConditions = linkedOrdersConditions.join('&');

        await this.client
            .get(
                endpoints.orderItem +
                'sort[0][0]=sort&' +
                'sort[0][1]=ASC&' +
                'limit=1000&' +
                orderItemsConditions
            )
            .then(list => {
                for (const item of list.items) {
                    if (item?.order?.id == this.order?.id) {
                        item.order = this.order;
                    }
                    if (!this.collapsibleItemsHaveBeenManuallyToggled && this.showCalculationByDefault && item.calculatedCostsEntries.length > 1) {
                        this.uncollapsedItems.push(item.id);
                    }
                    this.items.set(item.id, item);
                }
            });

        if (this.participantsEnabled) {

            await this.client
                .get(
                    'tourism/journey-participant?' +
                    'embeds[]=customer&limit=1500&' +
                    linkedOrdersConditions
                )
                .then(list => {
                    for (const participant of list.items) {
                        participant.items = [];
                        participant.bucket = [];
                        participant.objectLabel = participant?.customer?.displayName ?? participant?.customer?.fullName ?? this.i18n.tr("tourism-order.withoutName");
                        this.participants.set(participant.id, participant);
                    }
                });
        }

        if (this.participantsEnabled && !this.isStockOrder) {
            this.buckets = await this.bucketsGenerator.buildBuckets(
                this.items,
                this.participants,
                this.withoutParticipant,
                this.journeys,
                this.defaultSortableOptions
            );
        } else {
            console.log('FROM DATE');

            this.buckets = await this.fromDateBucketsGenerator.buildBuckets(
                this.items,
                this.participants,
                this.withoutParticipant,
                this.journeys,
                this.defaultSortableOptions,
                this.order,
                this.i18n
            );
        }


        const journeyTitles = [];

        let contentLocale = this.localeService.contentLocale;

        for (const journey of this.journeys.values()) {
            if (journey.tour) {
                journey.tour = await
                    this.client.get('tourism/tour/' + journey.tour.id);

                journeyTitles.push(
                    (journey?.tour?.title?.[contentLocale] ?? '') + (journey.journeyNumber ? (' / ' + journey.journeyNumber) : '') +
                    '<span class="label label-default" style="margin-left:1em">' +
                    moment(journey.startDate).format('L') + ' — ' +
                    moment(journey.endDate).format('L') + '</span>'
                );
            }
        }

        if (journeyTitles.length) {
            this.journeyTitle = journeyTitles.join('<br>');
        }

        for (let action of this.addActions) {
            action.hidden = action.hasOwnProperty('module') &&
                action.module.hasOwnProperty('if') &&
                !this.conditionMatcher.matchConditions(this.order, action.module.if);
        }

        this.loading = false;

        this.updateUncollapsedItems();
    }

    shouldDisplayAction(item, action) {
        //Avoid double display
        if (action.viewId === 'order/order-item-log') {
            return false;
        }

        return (!action.conditions || this.conditionMatcher.matchConditions(item, action.conditions)) && true !== action.bulkOnly;
    }

    displayActions(item) {
        const actions = item.actions ?? [];

        let filtered = actions.filter(action => this.shouldDisplayAction(item, action));

        let preparedActions = filtered.filter(action => action.preset !== 'edit' && action.preset !== 'display');

        let ids = _.isArray(item.id) ? item.id : [item.id];

        for (let i = 0; i < ids.length; i++) {

            // we need to use a new property, or it will be overwritten by the last one....
            let orderItem = this.items.get(ids[i]) ?? item;

            if (orderItem?.purchaseItemPrice) {
                preparedActions.push(Object.assign({
                    'label': this.i18n.tr("tourism-order.showReceipts") + (ids.length > 1 ? this.i18n.tr("tourism-order.forService") + (i + 1) : '')
                }, this.purchasePriceAction, {
                    actionCallback: async () => {
                        await this.openPurchasePrice(orderItem.id);
                    }
                }));
            }
        }

        for (let i = 0; i < ids.length; i++) {
            preparedActions.push(
                Object.assign({
                    'label': this.i18n.tr("tourism-order.showLog") + (ids.length > 1 ? this.i18n.tr("tourism-order.forService") + (i + 1) : '')
                }, {
                    actionCallback: async () => {
                        await this.openLogDialog(ids[i]);
                    }
                }));
        }

        return preparedActions.map(action => Object.assign({}, action, {showLabel: true}));
    }

    context(item, contextObjectRef) {
        return {id: item.id, modelId: item.modelId, contextObjectRef: contextObjectRef};
    }

    onSortableAdd(event) {
        logger.info("onSortableAdd", event, this);

        const bucket = 'without-participant' === event.target.id ? this.withoutParticipant :
            this.buckets[event.target.id.substr(7)]
        ;

        if (!this.selectedParticipants.length) {
            for (let participant of bucket.participants) {
                this.selectedParticipants.push(participant.id);
            }
        }

        this.unobserveItems();
        this.selectedItems = [event.detail.item.id];
        this.observeItems();

        this.executeAction({
            type: 'workflow',
            workflowId: 'tourism/copy-order-items',
            move: true
        });
    }

    canUpdateOrderItemInCart(item, cart) {
        return (item.canUpdate ?? true) && !cart.order.archived;
    }

    async onSortableUpdate(event) {
        logger.info("onSortableUpdate", event, this);
        this.sorting = true;


        const items = 'without-participant' === event.target.id ? this.withoutParticipant.items :
            this.buckets[event.target.id.substr(7)].items; // strip "bucket-"

        // noinspection JSIgnoredPromiseFromCall

        let id = items[event.oldIndex].id;
        id = _.isArray(id) ? id : [id];

        for (let x = 0; x < id.length; x++) {

            const newOrder = (await this.client.patch('order/order-items/' + this.order.id + '/resort', {
                item: id[x],
                sort: items[event.newIndex].sort
            })).data;

            const sortBucket = function (items, newOrder) {
                const sortedItems = [];

                _.forEach(newOrder, (itemId, pos) => {
                    const item = items.find(item => (_.isArray(item.id) ? item?.id[0] : item?.id) === itemId);

                    if (item) {
                        item.sort = pos;
                        sortedItems.push(item);
                    }
                });

                return sortedItems;
            };

            this.withoutParticipant.items = sortBucket(this.withoutParticipant.items, newOrder);
            _.forEach(this.buckets, (bucket) => {
                bucket.items = sortBucket(bucket.items, newOrder);
            });

        }

        this.sorting = false;

        return false;
    }

    addEmptyParticipant() {

        return this.actions.getAction({
            type: 'workflow',
            workflowId: 'tourism-order/participant-add',
            formId: 'tourism-order/participant-add'
        }, {id: this.order.id}).action();
    }

    addParticipant() {

        let orderOrganization = this.order.organization.parent ? this.order.organization.parent.id : this.order.organization.id;

        return this.dialogService
            .open({
                viewModel: UniversalEntitySelect,
                model: {
                    selectModelId: 'customer/customer',
                    title: this.i18n.tr("tourism-order.addCustomerAsParticipant"),
                    multiSelect: true,
                    conditions: {
                        organization: {
                            $eq: {
                                id: orderOrganization,
                                modelId: 'organization/organization'
                            }
                        }
                    }
                }
            })
            .whenClosed(data => {
                const items = data.output;

                if (data.wasCancelled || !items || 0 === items.length) {
                    return Promise.resolve(null);
                }

                return this.client
                    .patch('tourism-order/participant-customer-add/trigger/' + this.order.id, {customers: items})
                    .then(() => this.reloadOrder());
            });

    }

    _getParticipants(participants) {
        return participants.map(id => {
            return this.participants.get(id);
        });
    }

    _getParticipantTitle(participants) {
        return this._getParticipants(participants).sort((a, b) => a.num - b.num).map(participant => {
            let name = participant.num + '. ' + this.i18n.tr("tourism-order.participant");

            if (participant.customer) {
                return name + ': ' + participant.customer.displayName;
            } else {
                return name;
            }

        }).join('<br>');
    }

    async addToOrder(module) {
        if (
            module.needsParticipantSelection &&
            0 === this.selectedParticipants.length &&
            this.order.isb2b != true
        ) {
            this.flash.error(this.i18n.tr("order.error.firstSelectParticipant"));
            return;
        }

        let title = `${this.i18n.tr(module.longLabel ?? module.label)} ${this.i18n.tr('cart-view.toBooking')} ${this.order.orderNumber} ${this.i18n.tr('cart-view.add')}`;

        if (this.selectedParticipants.length > 0 || this.journeyTitle) {
            title += '<hr>';
        }

        if (this.journeyTitle) {
            title +=
                '<div class="pull-right text-right" style="max-width:66%;font-size:70%">' +
                this.journeyTitle +
                '</div>';
        }

        if (this.selectedParticipants.length > 0) {
            title +=
                '<div class="pull-left" style="max-width:32%;font-size:70%">' +
                this._getParticipantTitle(this.selectedParticipants) +
                '</div><div class="clearfix"></div>';
        }

        let promise;

        if (null == module.productSelectionModelId) {
            promise = Promise.resolve(null);
        } else {
            let viewModel = UniversalEntitySelect;
            let endpoint = null;

            if (module.productSelectionModelId == 'tourism-flight/itinerary') {
                viewModel = FlightDialog;
            } else if ([
                'tourism-service/service',
                'tourism-rental-car/rental-car-vehicle',
                'tourism-transfer/transfer',
                'tourism-event-booking/event',
                'tourism-travel-package/travel-package',
                'tourism-ship/ship',
                'tourism-ferry/ferry',
                'tourism-hotel-booking/room',
            ].indexOf(module.key) > -1) {
                viewModel = ProductSearch;
                endpoint = module.key;
            } else if (module.key == 'tourism-accommodation/room') {
                viewModel = ProductSearch;
                endpoint = 'tourism-accommodation/accommodation-interface';
            } else if (module.key == 'tourism-event/event') {
                viewModel = TourismEventSearch;
                endpoint = 'tourism-event/search';
            } else if (module.key == 'tourism-bus-routing/route-itinerary') {
                viewModel = RouteSearch;
            }

            promise = this.dialogService
                .open({
                    viewModel: viewModel,
                    model: {
                        participants: this._getParticipants(
                            this.selectedParticipants
                        ),
                        selectModelId: module.productSelectionModelId,
                        title: title,
                        multiSelect: false,
                        conditions: module.conditions,
                        viewContext: 'order/order',
                        order: this.order,
                        endpoint: endpoint,
                    },
                })
                .whenClosed((data) => {
                    const items = data.output;

                    if (data.wasCancelled || !items || 0 === items.length) {
                        return Promise.resolve(null);
                    }

                    return Promise.resolve(items);
                });
        }

        try {
            const items = await promise;

            if (module.productSelectionModelId != null) {
                if (!items || items.length === 0) {
                    return null;
                }
            }

            let productChoice = ProductChoice;

            console.log('Module key: ', module.key);
            if (module.key === 'tourism-insurance/api') {
                productChoice = InsuranceApiChoice;
            } else if (module.key === 'tourism-flight/fare-search') {
                productChoice = FlightSearch;
            } else if (module.key === 'transport/ticket') {
                productChoice = TicketSearch;
            }

            let apiReturnData;

            if (
                items?.length > 1 &&
                module.key !== 'tourism-insurance/api' &&
                module.key !== 'tourism-flight/fare-search' &&
                module.key !== 'transport/ticket'
            ) {
                const promisesArray = items.map((item) => {
                    const data = {
                        participants: this.selectedParticipants,
                        provider: module.key,
                        data: {id: item.id, modelId: item.modelId},
                        product: {id: item.id, modelId: item.modelId},
                    };

                    return this.formConfigLoader.get(
                        'order/order-item-add',
                        data
                    );
                });

                const formConfigs = await Promise.all(promisesArray);
                const itemsAndConfig = formConfigs.map((config, index) => {
                    return {
                        item: items[index],
                        formConfig: config,
                        data: {
                            participants: this.selectedParticipants,
                            provider: module.key,
                            order: {id: this.order.id, modelId: this.order.modelId},
                            product: {
                                id: items[index].id,
                                modelId: items[index].modelId,
                            },
                        },
                        formState: 'not-submitted',
                    };
                });

                const isOpenable = formConfigs.some((config) => {
                    return config.fields.some((field) => true !== field.hidden);
                });

                if (isOpenable) {
                    const data = await this.dialogService
                        .open({
                            viewModel: MultiProductChoice,
                            model: {
                                order: this.order,
                                title: title,
                                itemsAndConfig: itemsAndConfig,
                                participants: this._getParticipants(
                                    this.selectedParticipants
                                ),
                            },
                        })
                        .whenClosed();

                    if (!data.wasCancelled) {
                        apiReturnData = await data.output;
                    } else {
                        apiReturnData = 'cancelled';
                    }
                }

                for (const item of itemsAndConfig) {
                    if (isOpenable) {
                        continue;
                    }

                    await this.client.patch(
                        'order/order-item-add/trigger/' + this.order.id,
                        item.data
                    );
                }
            } else {
                const config = {
                    participants: this.selectedParticipants,
                    provider: module.key,
                    order: {id: this.order.id, modelId: this.order.modelId},
                };

                if (module.productSelectionModelId != null) {
                    config['product'] = {
                        id: items[0].id,
                        modelId: items[0].modelId,
                    };
                }
                const formConfig = await this.formConfigLoader.get(
                    'order/order-item-add',
                    config
                );

                if (formConfig.fields.some((field) => true !== field.hidden)) {
                    let isSunnyCar = config.provider === 'sunny-car/rental-car';
                    const data = await this.dialogService
                        .open({
                            viewModel: productChoice,
                            model: {
                                data: config,
                                order: this.order,
                                title: title,
                                participants: this._getParticipants(
                                    this.selectedParticipants
                                ),
                            },
                            lock: isSunnyCar,
                            overlayDismiss: !isSunnyCar
                        })
                        .whenClosed();

                    if (!data.wasCancelled) {
                        apiReturnData = data.output;
                    } else {
                        apiReturnData = 'cancelled';
                    }
                } else {
                    apiReturnData = config;
                }
            }
            if (
                apiReturnData &&
                apiReturnData != 'cancelled' &&
                apiReturnData?.type !== 'multi-product' &&
                apiReturnData !== "product-choice"
            ) {
                const response = await this.client.patch(
                    'order/order-item-add/trigger/' + this.order.id,
                    apiReturnData
                );
            }

            if (apiReturnData !== 'cancelled') {
                this.ea.publish('sio_form_post_submit', {
                    config: {modelId: 'order/order'},
                });

                this.flash.success('form.success');
            }
        } catch (error) {
            console.error(error);

            if (error && error.data) {
                if (error.data.localizedMessage) {
                    error = error.data.localizedMessage;
                } else if (error.data.message) {
                    error = error.data.message;
                }
            }

            this.flash.error(
                error || this.i18n.tr("order.error.unknownError")
            );
        }
    }

    selectedItemsChanged() {
        console.log('selected items changed', this.selectedItems);

        this.allItemsChecked = _.flatten(this.selectedItems).length >= this.items.size;

        if (this.selectAllCheckbox) {
            this.selectAllCheckbox.indeterminate = !this.allItemsChecked && !!this.selectedItems.length;
        }
    }

    selectAllItems() {
        this.unobserveItems();
        this.selectedItems = this.allItemsChecked ? Array.from(this.items.values()).map(i => i.id) : [];
        this.selectedParticipants = this.allItemsChecked ? Array.from(this.participants.values()).map(p => p.id) : [];
        this.observeItems();
    }

    canEditProductOptions(item) {
        let module = this.productProviders[item.provider];

        if (!module) {
            return false;
        }

        return module.supportsUpdate;
    }

    _getParticipantsAndContextIds(item) {
        let editParticipants = [];

        let ids = _.castArray(item.id);

        let contextIds = ids.filter(id => {
            const item = this.items.get(id);

            if (!item.participants || item.participants.length == 0) {
                return true;
            }

            let returnValue = false;

            for (let participant of item.participants) {
                if (this.selectedParticipants.includes(participant.id) || this.selectedParticipants.length == 0) {
                    returnValue = true;
                }
            }

            if (returnValue) {
                for (let participant of item.participants) {
                    editParticipants.push(participant.id);
                }
            }

            return returnValue;
        });

        return [
            contextIds,
            editParticipants
        ];
    }

    dropDownAction(action, item) {

        const [contextIds, editParticipants] = this._getParticipantsAndContextIds(item);

        console.log('ACTION', editParticipants, contextIds, action);

        let title = action.label ?? this.i18n.tr("tourism-order.editProductOptions");
        title = this.i18n.tr(title);

        if (editParticipants.length > 0) {
            title += '<hr><div class="pull-left" style="max-width:32%;font-size:70%">' +
                this._getParticipantTitle(editParticipants) +
                '</div><div class="clearfix"></div>';
        }

        if (contextIds.length == 0) {
            return Promise.resolve();
        }

        let context = {
            id: contextIds,
            modelId: item.modelId,
            title: title,
            disableLocalStorage: true,
        };

        return this.actions.getAction(action, context).action()
    }

    editProductOptions(item) {
        const [contextIds, editParticipants] = this._getParticipantsAndContextIds(item);

        console.log('EDIT PART', editParticipants, contextIds);

        let title = this.i18n.tr("tourism-order.editProductOptions");

        if (editParticipants.length > 0) {
            title += '<hr><div class="pull-left" style="max-width:32%;font-size:70%">' +
                this._getParticipantTitle(editParticipants) +
                '</div><div class="clearfix"></div>';
        }

        if (contextIds.length == 0) {
            return Promise.resolve();
        }

        let context = {
            id: contextIds,
            modelId: item.modelId,
            title: title,
            disableLocalStorage: true,
        };

        return this.actions.getAction({
            type: 'view',
            icon: 'fa fa-pencil',
            buttonClass: 'btn btn-danger btn-xs',
            viewId: 'order/order-item-update-from-provider',
            bulk: true,
            modal: true
        }, context).action()
    }

    /**
     * Edits options on order items
     * If participant is selected it edits only the items that belong to this participant
     * @param item
     */
    editItem(item) {
        const [contextIds, editParticipants] = this._getParticipantsAndContextIds(item);

        console.log('EDIT PART', editParticipants, contextIds);

        let title = this.i18n.tr("tourism-order.editService") + ': ' + item.title;

        if (editParticipants.length > 0) {
            title += '<hr><div class="pull-left" style="max-width:32%;font-size:70%">' +
                this._getParticipantTitle(editParticipants) +
                '</div><div class="clearfix"></div>';
        }

        if (contextIds.length == 0) {
            return Promise.resolve();
        }

        let context = {
            id: contextIds,
            modelId: item.modelId,
            title: title,
        };

        return this.actions.getAction({
            type: 'view',
            icon: 'fa fa-pencil',
            buttonClass: 'btn btn-danger btn-xs',
            viewId: 'order/order-item-edit',
            bulk: true,
            modal: true
        }, context).action()
    }

    executeAction(origAction) {
        const executionContext = {
            selectedParticipants: this.selectedParticipants,
            selectedItems: this._getSelectedItems(),
            participants: this.participants,
            items: this.items,
            allItemsChecked: this.allItemsChecked,
            order: this.order,
            journeyTitle: this.journeyTitle,
        };

        const action = _.defaults({}, origAction); // avoid modifying menu action

        return this.executorRegistry
            .execute(action, executionContext)
            .then(context => context && this.actions.getAction(action, context).action())
            .then(result => this.executorRegistry.afterExecutionCallback(action, executionContext, result))
            .then(data => {
                if (action.hasOwnProperty('downloadProperty')) {
                    const url = _.get(data, action.downloadProperty);

                    if (url) {
                        window.location.href = url;
                        return;
                    }
                } else if (action.hasOwnProperty('printProperty')) {

                    const url = _.get(data, action.printProperty);

                    //We can only download file using printjs if the CORS headers are set correct (which is only the case if file gets downloaded through CDN)
                    //Which is only configured on production server atm
                    if (url) {
                        printJS({printable: url, type: 'pdf'});
                    }
                }
                return this.reloadOrder();
            })
            .catch(error => {
                console.error(error);

                if (error && error.data) {
                    if (error.data.localizedMessage) {
                        error = error.data.localizedMessage;
                    } else if (error.data.message) {
                        error = error.data.message;
                    }
                }

                this.flash.error(error || this.i18n.tr("order.error.unknownError"));
                return this.reloadOrder();
            });
    }

    collapseBucket(bucketKey, collapsed) {
        const bucket = this.buckets.find(bucket => bucket.bucketKey === bucketKey);

        bucket.collapsed = collapsed;
    }

    uncollapseCostTable(itemKey, uncollapse) {
        this.collapsibleItemsHaveBeenManuallyToggled = true;
        if (uncollapse) {
            this.uncollapsedItems.push(itemKey);
        } else {
            this.uncollapsedItems = this.uncollapsedItems.filter(item => item != itemKey);
        }
        this.updateUncollapsedItems();
    }

    updateUncollapsedItems() {
        this.buckets.forEach(bucket => {
            bucket.items.forEach(item => {
                if (this.uncollapsedItems.includes(item.id)) {
                    item.uncollapsed = true;
                } else {
                    item.uncollapsed = false;
                }
            })
        })

        this.withoutParticipant.items.forEach(item => {
            if (this.uncollapsedItems.includes(item.id)) {
                item.uncollapsed = true;
            } else {
                item.uncollapsed = false;
            }
        })
    }

    selectParticipant(id) {
        const bucket = this.buckets.find(bucket => bucket.participants.some(participant => participant.id === id));
        const selected = this.selectedParticipants.includes(id);

        if (!selected) {
            // uncheck all bucket items if only connected to unchecked participant
            for (let participant of bucket.participants) {
                if (this.selectedParticipants.includes(participant.id)) {
                    return; // at least some other participant in this bucket is checked
                }
            }
        }

        const ids = bucket.items.map(item => item.id);

        if (selected) {
            for (id of ids) {
                if (!this.selectedItems.includes(id)) {
                    this.selectedItems.push(id);
                }
            }
        } else {
            this.unobserveItems();
            this.selectedItems = this.selectedItems.filter(id => !ids.includes(id));
            this.observeItems();
        }
    }

    _getSelectedItems() {

        console.log('SELECTED ITEMS', this.selectedItems);

        //Remove double items here
        const ids = _.uniq(_.flatten(this.selectedItems));

        if (!ids.length || !this.selectedParticipants.length || this.participants.size === this.selectedParticipants.length) {
            return ids;
        }

        return ids.filter(id => {
            const item = this.items.get(id);

            if (!item.participants || item.participants.length == 0) {
                return true;
            }

            for (let participant of item.participants) {
                if (this.selectedParticipants.includes(participant.id)) {
                    return true;
                }
            }

            return false;
        });
    }

    stringifyWarnings(warnings) {
        if (!warnings) {
            return '';
        }

        warnings = warnings.map(
            warning => "<div> - " + warning + "</div>"
        );

        return warnings.join("<br />");
    }

    async openPurchasePrice(id) {
        await this.dialogService.open({
            viewModel: CostsDialog,
            model: {
                destination: {id, modelId: "order/order-item"},
                item: {id, modelId: "order/order-item"}
            }
        }).whenClosed();
    }

    async openChargesContent(item) {
        await this.dialogService.open({
            viewModel: ChargesDialog,
            model: {
                item: item
            }
        }).whenClosed();
    }

    async openLogDialog(id) {
        await this.dialogService.open({
            viewModel: LogDialog,
            model: {
                object: {id, modelId: "order/order-item"}
            }
        }).whenClosed();
    }

    async openCalculatedPurchasePrice(id) {

        id = _.isArray(id) ? id[0] : id;

        const item = this.items.get(id)

        if (item?.calculatedCostsEntries?.length) {
            await this.dialogService.open({
                viewModel: CostsDialog,
                model: {
                    calculatedCostsEntries: item.calculatedCostsEntries,
                    item: {id: _.isArray(item.id) ? item.id[0] : item.id, modelId: "order/order-item"}
                }
            }).whenClosed();
        } else {
            this.flash.flash("info", this.i18n.tr("tourism-order.noDetailList"));
        }
    }

    getOrderProductProvider() {
        this.client.get('order/product-provider/' + this.order?.id, 60).then(modules => {

            modules = _.orderBy(modules, ['priority'], ['desc']);

            for (const module of modules) {

                if (!module.label || module.hide || module.key == 'order/product-prototype') {
                    continue;
                }

                if (!module.disabled) {
                    this.addActions.push({
                        actionClass: 'btn btn-sm btn-default',
                        icon: module.icon,
                        showLabel: module.showLabel ?? true,
                        textIcon: module.textIcon,
                        label: module.label,
                        help: module.help,
                        module: module
                    });
                }

                this.productProviders[module.key] = module;
            }
        });
    }
}
